<template>
  <div :class="$style.page">
    <div :class="$style.dealersTables">
      <div :class="$style.addDealersTable">
        <div :class="$style.addDealerButtons">
          <Autocomplete
            :class="$style.search"
            :search="searchDealer"
            placeholder="Поиск по email и названию компании дилера"
            :valueNames="['name']"
            @querySearch="querySearchDealers"
            @selectItem="handleSelectDealer"
            @handleFindItems="getDealers"
            @input="searchDealer = $event"
          />
          <adw-button @click="isOpenDropDown = !isOpenDropDown"
            >Фильтры</adw-button
          >
          <Dropdown
            :isOpenDropDown="isOpenDropDown"
            title="Фильтры дилера"
            :class="$style.dropdown"
            @submitFilters="handleSubmitFilters"
            @resetFilters="handleResetFilters"
          >
            <div :class="$style.filter">
              <p>Ценовая политика:</p>
              <el-select
                v-model="filters.pricePolicyId"
                filterable
                clearable
                remote
                placeholder="Выбрать"
              >
                <el-option
                  v-for="policy in pricesPolicy.data"
                  :key="policy.id"
                  :label="policy.name"
                  :value="policy.id"
                >
                  <span>{{ policy.name }}</span>
                </el-option>
              </el-select>
            </div>
            <div :class="$style.filter">
              <p>Забанен:</p>
              <el-select
                v-model="filters.isBanned"
                filterable
                clearable
                remote
                placeholder="Выбрать"
              >
                <el-option
                  v-for="(item, index) in $options.DEALERS_BOOLEANS"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                >
                  <span>{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
            <div :class="$style.filter">
              <p>Поставщик:</p>
              <el-select
                v-model="filters.isSupplier"
                filterable
                clearable
                remote
                placeholder="Выбрать"
              >
                <el-option
                  v-for="(item, index) in $options.DEALERS_BOOLEANS"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                >
                  <span>{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
            <div :class="$style.filter">
              <p>Участник БП:</p>
              <el-select
                v-model="filters.isMemberBP"
                filterable
                clearable
                remote
                placeholder="Выбрать"
              >
                <el-option
                  v-for="(item, index) in $options.DEALERS_BOOLEANS"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                >
                  <span>{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
            <div :class="$style.filter">
              <p>Тип бизнеса:</p>
              <el-select
                v-model="filters.businessType"
                filterable
                clearable
                remote
                placeholder="Выбрать"
              >
                <el-option
                  v-for="(item, index) in businessTypes"
                  :key="index"
                  :label="item.text"
                  :value="item.value"
                >
                  <span>{{ item.text }}</span>
                </el-option>
              </el-select>
            </div>
            <div :class="$style.filter">
              <p>Каталог:</p>
              <el-select
                v-model="filters.dealerCatalogId"
                filterable
                clearable
                remote
                placeholder="Выбрать"
              >
                <el-option
                  v-for="(item, index) in catalogList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                  <span>{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </Dropdown>
          <adw-button
            :disabled="!selectedDealersForExclude.length"
            @click="handleExcludeDealers"
            >Исключить дилеров</adw-button
          >
          <adw-button
            :disabled="!dealers?.list.length"
            @click="handleExcludeAllDealers"
            >Исключить всех</adw-button
          >
        </div>
        <el-table
          :data="dealers.list"
          height="45rem"
          @selection-change="
            handleSelectionChange($event, 'selectedDealersForExclude')
          "
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column property="name" label="Имя"></el-table-column>
          <el-table-column property="email" label="Почта"></el-table-column>
          <el-table-column label="Статус">
            <template slot-scope="scope"
              >{{ getStatusText(scope.row.isBanned) }}
            </template>
          </el-table-column>
          <el-table-column label="Цен.политика">
            <template slot-scope="scope"
              >{{ getPricePolicy(scope.row.pricePolicy) }}
            </template>
          </el-table-column>
          <el-table-column label="Тип бизнеса">
            <template slot-scope="scope">{{ scope.row.type }} </template>
          </el-table-column>
          <el-table-column label="Участник БП">
            <template slot-scope="scope"
              >{{ getBooleanText(scope.row.isBonusProgramMember) }}
            </template>
          </el-table-column>
          <el-table-column label="Поставщик">
            <template slot-scope="scope"
              >{{ getBooleanText(scope.row.isSupplier) }}
            </template>
          </el-table-column>
          <el-table-column label="Каталог">
            <template slot-scope="scope"
              >{{ scope.row.dealerCatalog?.name ?? 'По умолчанию' }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="totalDealers > 1"
          layout="prev, pager, next"
          :page-size="dealers.limit"
          :total="dealers.count"
          :current-page.sync="dealers.page"
          background
          :class="$style.pagination"
          @current-change="getNotExcludedDealersList"
        ></el-pagination>
      </div>
      <div :class="$style.deleteDealersTable">
        <div :class="$style.addDealerButtons">
          <Autocomplete
              :class="$style.search"
              :search="searchExcludedDealer"
              placeholder="Поиск по email и названию компании дилера"
              :valueNames="['name']"
              @querySearch="querySearchExcludedDealers"
              @selectItem="handleSelectExcludedDealer"
              @handleFindItems="getExcludedDealers"
              @input="searchExcludedDealer = $event"
            />
          <adw-button
            :disabled="!selectedDealersForInclude.length"
            :class="$style.deleteDealerButton"
            @click="handleIncludeDealers"
            >Добавить дилеров</adw-button
          >
        </div>
        <el-table
          :data="excludedDealers.list"
          height="45rem"
          @selection-change="
            handleSelectionChange($event, 'selectedDealersForInclude')
          "
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column property="name" label="Имя"></el-table-column>
          <el-table-column property="email" label="Почта"></el-table-column>
          <el-table-column label="Статус">
            <template slot-scope="scope"
              >{{ getStatusText(scope.row.isBanned) }}
            </template>
          </el-table-column>
          <el-table-column label="Цен.политика">
            <template slot-scope="scope"
              >{{ getPricePolicy(scope.row.pricePolicy) }}
            </template>
          </el-table-column>
          <el-table-column label="Тип бизнеса">
            <template slot-scope="scope">{{ scope.row.type }} </template>
          </el-table-column>
          <el-table-column label="Участник БП">
            <template slot-scope="scope"
              >{{ getBooleanText(scope.row.isBonusProgramMember) }}
            </template>
          </el-table-column>
          <el-table-column label="Поставщик">
            <template slot-scope="scope"
              >{{ getBooleanText(scope.row.isSupplier) }}
            </template>
          </el-table-column>
          <el-table-column label="Каталог">
            <template slot-scope="scope"
              >{{ scope.row.dealerCatalog?.name ?? 'По умолчанию' }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="totalExcludeDealers > 1"
          layout="prev, pager, next"
          :page-size="excludedDealers.limit"
          :total="excludedDealers.count"
          :current-page.sync="excludedDealers.page"
          background
          :class="$style.pagination"
          @current-change="getExcludedDealersList"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'

import Dropdown from '@/components/atoms/Dropdown'
import Autocomplete from '@/components/atoms/Autocomplete'

import { DEALERS_BOOLEANS, DEALERS_TYPES } from '@/constants/dealers'

export default {
  DEALERS_BOOLEANS,
  DEALERS_TYPES,
  components: { Dropdown, Autocomplete },
  mixins: [notifications],
  data() {
    return {
      searchExcludedDealer: '',
      searchDealer: '',
      isOpenDropDown: false,
      selectedDealersForExclude: [],
      selectedDealersForInclude: [],
      filters: {
        isBanned: null,
        isSupplier: null,
        isMemberBP: null,
        businessType: null,
        pricePolicyId: null,
        dealerCatalogId: null,
      },
      dealers: {
        list: [],
        page: 1,
        limit: 20,
        count: 0,
      },
      excludedDealers: {
        list: [],
        page: 1,
        limit: 20,
        count: 0,
      },
      pricesPolicy: [],
      catalogList: [],
    }
  },
  async created() {
    await Promise.all([
      this.getNotExcludedDealersList(),
      this.getDealersFilters(),
      this.getExcludedDealersList(),
    ])
  },
  computed: {
    totalDealers() {
      return Math.ceil(this.dealers.count / this.dealers.limit)
    },
    totalExcludeDealers() {
      return Math.ceil(this.excludedDealers.count / this.excludedDealers.limit)
    },
    businessTypes() {
      return [{ value: null, text: 'Пусто' }, ...DEALERS_TYPES]
    },
  },
  methods: {
    handleSelectionChange(val, name) {
      this[name] = val
    },
    getStatusText(isBanned) {
      return isBanned ? 'Забанен' : 'Активен'
    },
    getBooleanText(boolean) {
      return boolean ? 'Да' : 'Нет'
    },
    getPricePolicy(pricePolicy) {
      return !pricePolicy || pricePolicy.name === ''
        ? 'Cтандартная'
        : pricePolicy.name
    },
    async getDealersFilters() {
      const loading = this.$loading({
        lock: true,
      })

      const { pricesPolicy, catalogList, error } =
        await delivery.AddwineCore.DealersActions.getDealersFilters()

      loading.close()

      if (error) return

      this.pricesPolicy = pricesPolicy
      this.catalogList = catalogList?.data ?? []
    },
    async getDealers() {
      this.dealers.page = 1
      await this.getNotExcludedDealersList()
    },
    async getExcludedDealers() {
      this.dealers.page = 1
      await this.getExcludedDealersList()
    },
    async querySearchDealers({ queryString, setSearchItems }) {
      const query = {
        limit: 10,
        search: queryString,
      }
      const { value, error } =
        await delivery.AddwineCore.DealersActions.getList(query)
      if (error) return
      setSearchItems(value.data)
    },
    async querySearchExcludedDealers({ queryString, setSearchItems }) {
      const query = {
        limit: 10,
        search: queryString,
        excludedFromBonusProgramId: this.$route.params.id
      }
      const { value, error } =
        await delivery.AddwineCore.DealersActions.getList(query)
      if (error) return
      setSearchItems(value.data)
    },
    handleSelectDealer(selectedDealer) {
      this.selectedDealersForExclude = [selectedDealer]
      this.handleExcludeDealers()
    },
    handleSelectExcludedDealer(selectedDealer) {
      this.selectedDealersForInclude = [selectedDealer]
      this.handleIncludeDealers()
    },
    async getNotExcludedDealersList(withoutPagination = false) {
      const loading = this.$loading({
        lock: true,
      })

      const params = {
        page: this.dealers.page,
        limit: this.dealers.limit,
        search: this.searchDealer,
        pricePolicyId: this.filters.pricePolicyId,
        isBanned: this.filters.isBanned,
        isSupplier: this.filters.isSupplier,
        memberBP: this.filters.isMemberBP,
        type: this.filters.businessType,
        dealerCatalogId: this.filters.dealerCatalogId,
        notExcludedFromBonusProgramId: this.$route.params.id,
        withoutPagination: withoutPagination
      }

      const { value, error } =
        await delivery.AddwineCore.DealersActions.getList(params)

      loading.close()

      if (error) return

      this.dealers.list = value.data
      this.dealers.count = value.meta.count
    },
    async getExcludedDealersList() {
      const loading = this.$loading({
        lock: true,
      })

      const params = {
        page: this.excludedDealers.page,
        limit: this.excludedDealers.limit,
        excludedFromBonusProgramId: this.$route.params.id,
      }

      const { value, error } =
        await delivery.AddwineCore.DealersActions.getList(params)

      loading.close()

      if (error) return

      this.excludedDealers.list = value.data
      this.excludedDealers.count = value.meta.count
    },
    async handleExcludeDealers() {
      const excludedDealersIds = this.selectedDealersForExclude.map(
        (dealer) => dealer.id,
      )

      const data = {
        ids: excludedDealersIds,
      }

      const loading = this.$loading({
        lock: true,
      })

      const { error } =
        await delivery.AddwineCore.BonusProgramActions.excludeDealers(
          this.$route.params.id,
          data,
        )

      loading.close()

      if (error) {
        this.showNotification('Ошибка исключения дилеров', 'error')
        return
      }

      this.dealers.page = 1
      await this.getExcludedDealersList()
      await this.getNotExcludedDealersList()
    },
    async handleExcludeAllDealers() {
      await this.getNotExcludedDealersList(true)

      const excludedDealersIds = this.dealers.list.map((dealer) => dealer.id)

      const data = {
        ids: excludedDealersIds,
      }

      const loading = this.$loading({
        lock: true,
      })

      const { error } =
        await delivery.AddwineCore.BonusProgramActions.excludeDealers(
          this.$route.params.id,
          data,
        )

      loading.close()

      if (error) {
        this.showNotification('Ошибка исключения дилеров', 'error')
        return
      }

      this.dealers.page = 1
      await this.getExcludedDealersList()
      await this.getNotExcludedDealersList()
    },
    async handleIncludeDealers() {
      const includedDealersIds = this.selectedDealersForInclude.map(
        (dealer) => dealer.id,
      )

      const data = {
        ids: includedDealersIds,
      }

      const loading = this.$loading({
        lock: true,
      })

      const { error } =
        await delivery.AddwineCore.BonusProgramActions.includeDealers(
          this.$route.params.id,
          data,
        )

      loading.close()

      if (error) {
        this.showNotification('Ошибка добавления дилеров', 'error')
        return
      }

      this.excludedDealers.page = 1

      await this.getNotExcludedDealersList()
      await this.getExcludedDealersList()
    },

    async handleResetFilters() {
      this.filters.pricePolicyId = null
      this.filters.isBanned = null

      this.isOpenDropDown = false

      await this.getNotExcludedDealersList()
    },
    async handleSubmitFilters() {
      this.dealers.page = 1

      this.isOpenDropDown = false

      await this.getNotExcludedDealersList()
    },
  },
}
</script>
<style lang="scss" module>
.page {
  padding: 1rem;

  .dealersTables {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    gap: 1.5rem;
    margin: 2rem 0;

    .dropdown {
      .filter {
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          margin-right: 1rem;
        }
      }
    }

    & > div {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 65rem;
      gap: 1rem;
      height: 45rem;

      .addDealerButtons {
        position: relative;
        display: flex;
        gap: 1rem;
        justify-content: flex-end;
      }

      .deleteDealerButton {
        & > div {
          display: flex;
          justify-content: flex-end;
        }
      }

      .pagination {
        display: flex;
        justify-content: center;
        margin-bottom: -3.15rem;
      }
    }
  }
}
</style>
